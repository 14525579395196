.greeting-container-outer {
    width: 100%;
    background-color: #EAF1F5;
}

.greeting-container-inner {
    display: flex;
    flex-direction: row;
    padding-top: 64px;
}

.greeting-container-inner > div:first-child {
    flex: 1;
}

.greeting-container-inner > div:first-child > img {
    width: 300px;
    max-width: 250px;
    margin-left: 32px;
    margin-bottom: 20%;
}

.greeting-container-inner > div:last-child {
    display: flex;
    flex-direction: column;
    flex: 3;
    margin-left: 32px;
}

.greeting-container-inner > div:last-child > h2 {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 2rem;
    font-weight: normal;
}

.greeting-container-inner > div:last-child p {
    font-family: 'Noto Sans KR', sans-serif;
    color: var(--primary-text-color);
    font-size: 1rem;
    line-height: 2;
}

.greeting-container-inner > div:last-child > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.greeting-container-inner > div:last-child > div > p  {
    margin: 0 16px 0 0;
    font-size: 1.125rem;
}

.greeting-sign-image {
    max-width: 120px;
}