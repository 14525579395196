.certificate-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.certificate-title {
    font-weight: bold;
    text-align: center;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1rem;
    color: var(--primary-text-color);
}