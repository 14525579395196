header {
    height: 100px;
    width: 100%;
    background-color: white;
    border-bottom: 0.5px solid #e0e0e0;
}

header > .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

/* 로고를 감싸는 컨테이너 */
#header-logo-container {
    width: 230px;
}

#header-logo-container img {
    width: 85px;
    height: 75px;
}

/* Submenu Header */
#header-submenu {
    display: flex;
    flex-direction: row;
    height: 100%;
    flex: 2;
}

.header-submenu-item {
    display: flex;
    align-items: center;
    width: 180px;
    cursor: pointer;
}

.header-submenu-item > p {
    margin: 0;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 300;
    color: var(--primary-text-color);
    line-height: 80px;
}

/* Submenu Hover css handler */
.header-submenu-item > p:hover {
    border-bottom: 3px solid var(--primary-accent-color);
}

.header-submenu-item:hover ~ #submenu-container, #submenu-container:hover {
    height: 280px;
}

/* Submenu Container */
#submenu-container {
    position: absolute;
    top: 100px;
    left: 0;
    height: 0;
    width: 100%;
    overflow: hidden;
    transition: height 0.35s;
    z-index: 10;
    background-color: white;
    min-width: 1024px;
}

#submenu-container > .container {
    display: flex;
    flex-direction: row;
}

#submenu-container table {
    margin-top: 16px;
    font-family: 'Noto Sans KR', sans-serif;
}

#submenu-container table th {
    width: 180px;
    height: 38px;
}

#submenu-container table td {
    font-weight: 300;
    font-size: 0.92rem;
    height: 28px;
}

#submenu-container table a {
    color: var(--primary-text-color);
}