
.recruit-progress-item {
    flex: 1;
}

.CircularProgressbar-path {
    stroke: var(--primary-accent-color);
    stroke-width: 1px;
}
.CircularProgressbar-trail {
    stroke: #E0E0E0;
    stroke-width: 1px;
}

.recruit-progress-icon {
    width: 48px;
    height: 48px;
}

.recruit-progress-item > h3 {
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    font-family: 'Noto Sans KR', sans-serif;
}

.recruit-progress-item > p {
    font-size: 0.9rem;
    text-align: center;
    color: var(--primary-text-color);
    font-family: 'Noto Sans KR', sans-serif;
    word-break: keep-all;
}

.recruit-progress-hr-container {
    flex: 1;
    height: 150px;
    display: flex;
    align-items: center;
}

.recruit-progress-hr-container > div {
    width: 100%;
    height: 2px;
    background-color: #E0E0E0;
}