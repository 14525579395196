.core-value-item {
    display: flex;
    flex-direction: row;
}

.core-value-item-vertical {
    display: flex;
    flex-direction: column-reverse;
    width: 250px;
    align-items: center;
}

.core-value-item > img {
    width: 64px;
    height: 64px;
}

.core-value-item-vertical > img {
    width: 96px;
    height: 96px;
}


.core-value-container {
    margin: 8px 16px 0 24px;
}

.core-value-container > h3 {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: 'Noto Sans KR', sans-serif;
}

.core-value-container-text-center {
    text-align: center;
}

.core-vale-container-context-height {
    height: 96px;
}

.core-value-container > p {
    font-size: 0.95rem;
    font-family: 'Noto Sans KR', sans-serif;
    color: var(--primary-text-color);
    word-break: keep-all;
}

.core-value-table td {
    width: 420px;
    padding-bottom: 16px;
}