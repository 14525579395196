.business2-item-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    min-height: 260px;
}

.business2-item-container > ul, .business2-item-container > p {
    flex: 1;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    word-break: keep-all;
}

.business2-item-container > .image-container {
    flex: 1;
}

.image-container > img {
    width: 100%;
    height: 260px;
    object-fit: cover;
    margin-top: -64px;
}

.business2-item-container li {
    margin-bottom: 8px;
}