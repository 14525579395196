.page-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    white-space: pre-wrap;
}

.page-section-hr {
    width: 32px;
    height: 3px;
    background-color: var(--primary-accent-color);
    margin-bottom: 8px;
}

.page-section > div:first-child {
    flex: 1;
    padding-right: 64px;
}

.page-section > div:last-child {
    flex: 3;
}

.page-section > div:first-child > h1 {
    font-size: 2rem;
    font-family: 'Noto Sans KR', sans-serif;
}

.page-section-content {
    color: var(--primary-text-color);
    font-family: 'Noto Sans KR', sans-serif;
    margin-top: 12px;
    font-size: 1.15rem;
    line-height: 1.75;
    word-break: keep-all;
    white-space: pre-wrap;
}
