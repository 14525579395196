.submenu-pagination {
    width: 100%;
    height: 64px;
    border-bottom: 0.5px solid #e3e3e3;
    display: flex;
    align-items: center;
}

#submenu-pagination-container {
    display: flex;
    flex-direction: row;
}

.link-color {
    color: var(--primary-text-color);
}

.breadcrumb-item {
    width: 140px;
    display: inline-block;
    margin-right: 16px;
}