/*
    Slider 에 들어가는 이미지에 대한 스타일시트
 */
.ssi-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ssi-image-container > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(60%);
}

/*
    Slider 에 텍스트에 대한 스타일 시트
 */
.ssi-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.ssi-container h1 {
    color: white;
    font-size: 5rem;
    font-family: 'Montserrat', sans-serif;
    margin: 0 12.5% 0 0;
}

.ssi-container p {
    color: #EBEBEB;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: lighter;
    margin-right: 12.5%;
    font-size: 1.25rem;
}

.ssi-container hr {
    width: 100%;
    color: #6d6d6d;
    height: 2px;
}