.history-date {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: var(--primary-text-color);
    margin-bottom: 4px;
}

.history-content {
    font-family: 'Noto Sans KR', sans-serif;
    color: var(--primary-text-color);
    font-size: 1rem;
    margin-bottom: 32px;
}

.history-container {
    display: flex;
    justify-content: center;
    margin-top: 180px;
}

.history-container img {
    position: relative;
    width: 400px;
    height: 250px;
    object-fit: contain;
    left: -412px;
    bottom: 200px;
}

.history-item-with-image {
    height: 180px;
    
}

.history-container .img-container {
    width: 0;
    height: 0;
}

.history-timeline {
    animation-duration: 0.75s;
    animation-name: fade-in;
    animation-fill-mode: forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translate(0, 30px);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}