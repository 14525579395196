.submenu-header {
    width: 100%;
    height: 330px;
    position: relative;
}

.submenu-header > div:first-child {
    width: 100%;
    height: 330px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submenu-header > div:first-child > img {
    filter: brightness(55%);
    width: 100%;
    height: 330px;
    object-fit: cover;
}

.submenu-header > .submenu-header-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.submenu-header-container > .container > h1 {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-weight: 500;
    font-size: 2.5rem;
}

.submenu-header-container > .container > p {
    font-family: 'Noto Sans KR', sans-serif;
    color: #dedede;
}