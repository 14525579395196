#logo-big {
    width: 128px;
    margin-top: 32px;
}

#color-rule-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.color-rule-item {
    width: 240px;
    border: none;
    padding: 0;
    margin: 0;
}

.color-palette {
    width: 100%;
    height: 96px;
}

.color-rule-title {
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 4px;
}

.color-rule-content {
    padding: 0;
    margin: 0;
    color: var(--primary-text-color);
    font-size: 1rem;
    font-weight: lighter;
    font-family: 'Noto Sans KR', sans-serif;
}