footer {
    width: 100%;
    height: 200px;
    background-color: #EAEAEA;
    display: flex;
    align-items: center;
}

.footer-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
}

.footer-container > div:nth-child(1) {
    flex: 1;
}

.footer-container > div:nth-child(2) {
    flex: 4;
}

.footer-container > div:nth-child(2) > p {
    color: #777777;
    margin: 0 0 4px;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 0.9rem;
}

.footer-container > div:nth-child(3) {
    flex: 1;
}

.footer-link-table td {
    text-align: center;
}

.sns-image {
    width: 28px;
}