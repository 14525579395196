.board-read-container {
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    margin-bottom: 64px;
}

.board-read-container > h1 {
    font-weight: bold;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 2.75rem;
    margin-bottom: 32px;
}

#board-read-info {
    color: var(--primary-text-color);
    font-size: 1rem;
    font-family: 'Noto Sans KR', sans-serif;
}

.board-read-hr {
    width: 100%;
    height: 0.5px;
    background-color: #e7e7e7;
    margin-top: 24px;
    margin-bottom: 24px;
}

.board-read-backbutton {
    width: 100px;
    margin-left: auto;
    margin-top: 32px;
}

.board-loading-container {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}